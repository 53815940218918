import { useState } from "react";
import React from "react";
import Product from "../Product/Product";

const Category = React.forwardRef((props, ref) => {
    const { product } = props;
    product.products = props.product.products;

    return (
        <div key={product.id} ref={ref} id={props.id_param}>
            <h1>{product.title}</h1>
            <div className="row">
                {product.products.map((product) => (
                    <Product prod={product} sp={props.setProduct} ci={props.CartItems} />
                ))}
            </div>
        </div>
    );
});

export default Category;
