import React from "react"; // Import React if not already imported
import './Card_styles.css'
const Cart = (props) => {
    return (
        <div>
            <div className="">
                <div className="row">
                        <div style={{maxWidth: "500px"}}>
                        <div className="card mb-4">
                            <div className="card-body">
                                <h5 className="card-title">Ваш заказ</h5>
                                {props.prods.length === 0 ?? <div className="alert alert-secondary" role="alert">Корзина пуста</div>}
                                <div className="cart-wrapper">
                                    {props.prods.map((productInfo) => (
                                        <div id="card" data-id={productInfo.id} key={productInfo.id} className="cart-item">
                                            <div className="cart-item__top">
                                                <div className="cart-item__img">
                                                    <img className="product-img" src={`http://localhost:8083/photo/JPEG/${productInfo.img}`} alt={productInfo.title} />
                                                </div>
                                                <div className="cart-item__desc">
                                                    <div className="cart-item__title">{productInfo.title}</div>
                                                    <div className="cart-item__weight">{productInfo.weight}g</div>
                                                    <div className="cart-item__details">
                                                        <div className="items items--small counter-wrapper">
                                                            <button className="items__control" onClick={()=> {


                                                                    // Check if the product is already in the cart
                                                                    const existingProductIndex = props.prods.findIndex((item) => item.id === productInfo.id);

                                                                    if (productInfo.quantity > 1 ) {
                                                                        // If the product is in the cart, increase its quantity
                                                                        const updatedCart = [...props.prods];
                                                                        updatedCart[existingProductIndex].quantity -= 1;
                                                                        props.setprods(updatedCart);
                                                                    } else {
                                                                        console.log("Yeet!")
                                                                        // If the product is not in the cart, add it with a quantity of 1
                                                                        var updatedCart= props.prods.filter((item) => item.id !== productInfo.id)
                                                                        console.log(productInfo.id)
                                                                        console.log(props.prods)
                                                                        console.log(updatedCart)
                                                                        props.setprods(updatedCart);
                                                                    }
                                                                }
                                                            } data-action="minus">-</button>
                                                            <div className="items__current" data-counter="">
                                                                {productInfo.quantity}
                                                            </div>
                                                            <button className="items__control" onClick={()=> {
                                                                const existingProductIndex = props.prods.findIndex((item) => item.id === productInfo.id);

                                                                if (existingProductIndex !== -1) {
                                                                    // If the product is in the cart, increase its quantity
                                                                    const updatedCart = [...props.prods];
                                                                    updatedCart[existingProductIndex].quantity += 1;
                                                                    props.setprods(updatedCart);
                                                                } else {
                                                                    // If the product is not in the cart, add it with a quantity of 1
                                                                    const updatedCart = [...props.prods, { ...props.prods, quantity: 1 }];
                                                                    props.setprods(updatedCart);
                                                                }}}>+</button>
                                                        </div>
                                                        <div className="price">
                                                            <div className="price__currency">{productInfo.price}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/* // cart-wrapper */}

                                {/* Стоимость заказа */}
                                <div className="cart-total">
                                    <p>
                                        <span className="h5">Доставка:</span>
                                        <span className="delivery-cost">250 ₽</span><br />
                                        <span className="small">Бесплатно при заказе от 2000 ₽</span>
                                    </p>
                                    <p>
                                        <span className="h5">Итого:</span>
                                        <span className="total-price">0</span>
                                        <span className="rouble">₽</span>
                                    </p>
                                </div>
                            </div>
                            <div id="order-form" className="card-body border-top order-form-container">
                                <h5 className="card-title title-padding">Оформить заказ</h5>
                                <div className="isAddr content-padding">Адрес не выбран</div>
                                <form id="form1">
                                    <div className="form-group">
                                        <input type="text" className="form-control input-padding" name="number" required placeholder="Ваш номер телефона" />
                                        <input type="text" className="form-control input-padding" name="name" placeholder="Как вас зовут" />
                                        <input type="text" className="form-control input-padding" name="comment" placeholder="Комментарий к заказу" />
                                        <input type="text" className="form-control input-padding" name="adr" placeholder="Введите ваш адрес" />
                                        <div className="yvi content-padding" id="txt"></div>
                                        <div className="yvi content-padding" id="adr"></div>
                                    </div>
                                    <button id="subbutt" className="card-button button-padding"><h5>Заказать</h5></button>
                                </form>
                                <p className="content-padding">В случае уточнения доставки вам перезвонит курьер</p>
                                <p className="content-padding">Оплата курьеру</p>
                                <p className="content-padding">В руки наличными</p>
                                <p className="content-padding">хорошего дня</p>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cart;
