import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AdminMain from "./components/admin/admin-main";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import FallbackRoller from "./components/util/fallback/roller";
import LoginForm from "./components/admin/security/LoginForm";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,

    },
    {
        path:"/admin",
        element: <AdminMain />

    },
    {
        path: "/login",
        element: <LoginForm />
    }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider
        router={router}
        fallbackElement={<FallbackRoller />}
    />

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
