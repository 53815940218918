import axios from "axios";
import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "./main.css"
import Category from "./Category/Category";
import Button from "bootstrap/js/src/button";
import Cart from "../cart/Cart";

const ProductList = (props) => {
    const [products, setProducts] = useState([]);
    const categoryRefs = useRef([]); // Create an array of refs for categories
    const [selectedProducts, setSelectedProducts] = useState([])
    useEffect(() => {
        getProducts()
            .then((data) => {
                setProducts(data);
            })
            .catch((error) => {
                console.error("Error fetching products:", error);
            });
    }, []);

    // Scroll to the category when a button is clicked
    const scrollToCategory = (categoryId) => {
        // TODO Вроде это является официальным решением, но что то мне подсказывает, что оно пахнет хуйней. Разберись с forwardRef и настрой оный
        const element = document.getElementById(`category//scrollable//${categoryId}`)
        element.scrollIntoView({behavior:"smooth"})
    };

    return (
        <div>
            {products.map((category) => (
                <Category
                    key={category.id}
                    product={category}
                    setProduct={setSelectedProducts}
                    CartItems={selectedProducts}
                    ref={(ref) => categoryRefs.current.push({ id: category.id, ref })}
                    id_param={`category//scrollable//${category.id}`}
                />
            ))}
            <Cart prods={selectedProducts} setprods={setSelectedProducts}/>
            <div className="prokrutka">
                <div id="roller" className="RollerBtn">
                    {products.map((category) => (
                        <button
                            key={category.id}
                            className="RollerBtn"
                            onClick={() => {
                                scrollToCategory(category.id);
                                console.log(category.id)
                            }}
                        >
                            <img src={`http://localhost:80803/photo/{category.icon}`} alt={category.title} />
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

async function getProducts() {
    try {
        const response = await axios.get("https://kudiyarapi.gigagile.space/products",{
                headers : {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "*"
                }
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
}

export default ProductList;

//